import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "svNavbar",
  "title": "ttt",
  "left": {
    "logo": "/img/zimply-logo-color.png",
    "brand": "/img/zimply-logo-text-white.png",
    "brandDark": "/img/zimply-text-black.png",
    "brandLight": "/img/zimply-text-white-2.png"
  },
  "links": [{
    "title": "Digitala assistenter",
    "path": "digitalassistants"
  }, {
    "title": "Om oss",
    "path": "-",
    "childLinks": [{
      "title": "Om oss",
      "path": "about"
    }, {
      "title": "Vårt team",
      "path": "team"
    }]
  }, {
    "title": "Case",
    "path": "-",
    "childLinks": [{
      "title": "Svea",
      "path": "case/svea-bank"
    }, {
      "title": "Acne",
      "path": "case/acne-studios"
    }, {
      "title": "Futur Pension",
      "path": "case/futur-pension"
    }, {
      "title": "Wise Professionals",
      "path": "case/wise-professionals"
    }, {
      "title": "SBB",
      "path": "case/sbb"
    }, {
      "title": "Dina Försäkringar",
      "path": "case/dina"
    }]
  }, {
    "title": "Intelligent automation",
    "path": "-",
    "childLinks": [{
      "title": "Överblick",
      "path": "automation"
    }, {
      "title": "RPA",
      "path": "automation/rpa"
    }, {
      "title": "Chatbot",
      "path": "automation/chatbot"
    }, {
      "title": "Machine learning",
      "path": "automation/machine-learning"
    }, {
      "title": "Samtalsanalys",
      "path": "automation/samtalsanalys"
    }, {
      "title": "NLP",
      "path": "automation/nlp"
    }, {
      "title": "OCR",
      "path": "automation/ocr"
    }, {
      "title": "Computer Vision",
      "path": "automation/computer-vision"
    }]
  }, {
    "title": "Jobba här",
    "path": "career"
  }, {
    "title": "Kontakt",
    "path": "contact"
  }],
  "right": {
    "showDemo": true,
    "buttonText": "Boka demo",
    "path": "demo"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      